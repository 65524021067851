import React from "react"
import styled from 'styled-components'
import Link from 'gatsby-link'

import "aos/dist/aos.css";

import Layout from "../components/layout"
import SEO from "../components/seo"

import Container from '../components/Atoms/Container'

const NotFound = styled.section`
  background-color: ${p => p.theme.color.burgundy};
  background: rgb(89,26,42);
  background: linear-gradient(180deg, rgba(89,26,42,1) 0%, rgba(97,33,49,1) 100%);
  min-height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledContainer = styled(Container)`
  text-align: center;
`
const Oops = styled.h1`
  font-family: ${p => p.theme.font.objectives};
  color: #471521;
  font-size: 9rem;
  line-height: 80%;
  ${p => p.theme.mediaQueries.ipadUp}{
    font-size: 20rem;
  }
  ${p => p.theme.mediaQueries.largeUp}{
    font-size: 30rem;
  }
`
const OopsMessage = styled.p`
  color: white;
  font-family: ${p => p.theme.font.body};
  font-size: 1.275rem;
  line-height: 1.8rem;
  padding-top: 30px;
`
const Button = styled(Link)`
  display: inline-block;
  color: ${p => p.theme.color.gold};
  font-family: ${p => p.theme.font.body};
  background-color: transparent;
  border: 1px solid ${p => p.theme.color.gold};
  padding: 10px 50px; 
  text-transform: lowercase;
  text-decoration: none;
  text-align: center;
  margin-top: 25px;
  width: 250px;
  transition: 0.3s ease-in-out;
  &:hover{
    background-color: ${p => p.theme.color.gold};
    color: ${p => p.theme.color.burgundy};
  }
`

class NotFoundPage extends React.Component{
  componentDidMount() {
      const AOS = require('aos')
      this.aos = AOS
      this.aos.init()
    }
  
    componentDidUpdate() {
      this.aos.refresh()
    }
  render(){
  return(
    <Layout>
      <SEO title="404: Not found" />
      <NotFound>
        <StyledContainer>
          <div>
            <Oops data-aos="fade-up" 
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-delay="50" >404!</Oops>
            <OopsMessage data-aos="fade-up" 
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-delay="50">Oops! We can't seem to find the page you are looking for. <br />Try heading back to our home page.</OopsMessage>
            <Button to="/" data-aos="fade-up" 
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-delay="50">Home</Button>
          </div>
        </StyledContainer>
      </NotFound>
    </Layout>
    )
  }
}

export default NotFoundPage
